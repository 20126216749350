// import '@global/eventHub';
import Vue from 'vue';
import Product from '@mixins/Product';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
import Storage from '@plugins/StoragePlugin';
Vue.use(breakpoints);
Vue.use(Storage, {
    prefix: 'global',
});
new Vue({
    el: '#home-page-wrapper',
    data() {
        return {
            showBanner: true,
        };
    },
    mixins: [Product],
    // mounted() {
    //   this.showBanner = this.$getStorage('show_home_banner', true);
    // },
    methods: {
        OnMotivationalBlockClick($event, type) {
            var _a;
            this.$setStorage('usp_type', type);
            if (this.$mediaBreakpointDown('lg')) {
                $event.stopPropagation();
                $event.preventDefault();
                (_a = document.getElementById('main-menu__hamburger__mobile')) === null || _a === void 0 ? void 0 : _a.click();
            }
        },
        OnCloseBanner() {
            this.showBanner = false;
            // this.$setStorage('show_home_banner', false);
        },
    },
});
