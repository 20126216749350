import Vue from 'vue';
export default Vue.extend({
    data() {
        return {
            touchDevice: 'ontouchstart' in document.documentElement,
        };
    },
    methods: {
        /**
         * Checking if touch device
         */
        isTouchDevice() {
            return this.touchDevice;
        },
    },
});
