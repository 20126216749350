import '@global/eventHub';
import Vue from 'vue';
import ApiRequest from '@services/ApiRequestService';
export default Vue.extend({
    data() {
        return {
            cartUrl: '/cart/',
            swaCartUrl: '/api/sellerapi/cart/',
            sellerId: null,
        };
    },
    mounted() {
        window.eventHub.$on('sellingPointUpdated', (params) => {
            this.seller_id = params.seller_id;
        });
    },
    methods: {
        UpdateQuantity(adjust) {
            this.qty = parseInt(this.qty);
            if (this.qty + adjust > 0) {
                this.qty += adjust;
            }
        },
        AddToCart(id, type = 0, swa = false) {
            if (swa) {
                window.QsshopSwa.addToCart(this.id || id, type, this.qty || 1);
                return;
            }
            let self = this;
            let data = {
                qty: self.qty || 1,
                type: type,
                seller_id: this.seller_id,
            };
            ApiRequest.post(this.cartUrl + (self.id || id), data).then((response) => {
                let data = response.data.message;
                window.notifications.$flash(data.message, 'success');
                window.eventHub.$emit('CartQuantityUpdate', self.id, data.quantity);
            }, (error) => { });
            return;
        },
        AddToCartSwa(id, type = 0) {
            window.QsshopSwa.addToCart(this.id || id, type, this.qty || 1);
            return;
        },
        RemoveFromCart(id) {
            let self = this;
            ApiRequest.delete(this.cartUrl + self.id).then((response) => {
                let data = response.data.message;
                window.notifications.$flash(data.message, 'success');
                window.eventHub.$emit('CartQuantityUpdate', self.id, data.quantity);
            }, (error) => {
                if (error.message) {
                    window.notifications.$flash(error.message, 'error');
                }
            });
            return;
        },
    },
});
