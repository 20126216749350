import Vue from 'vue';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
// import values from 'lodash/values';
import ProductCart from '@mixins/ProductCart';
export default Vue.extend({
    data() {
        return {
            current: {},
            availability: String,
            price: Number,
            original_price: Number,
            percentage_discount: Number,
            discount_formatted: String,
            multiPurchase: Boolean,
            skuList: Array,
            currentFormatted: {},
            // installment12: String,
            // installment24: String,
            thumbnailSrc: '',
        };
    },
    mixins: [ProductCart],
    watch: {
        current() {
            forEach(this.variationsList.attributes, (attr, key) => {
                let option = find(attr.values, (item) => {
                    return item.id === this.current.values[key];
                });
                this.currentFormatted[attr.name] = {
                    value: option ? option.value : null,
                    image: option ? option.image : null,
                };
            });
        },
    },
    methods: {
        BindSliders: function (thumbnailSelector, mainImageSelector, direction, thumbWidth) {
            $(thumbnailSelector).flexslider({
                animation: 'slide',
                controlNav: false,
                animationLoop: false,
                slideshow: false,
                itemWidth: thumbWidth,
                itemMargin: 12,
                asNavFor: mainImageSelector,
                direction: direction,
                startAt: 0,
            });
            $(mainImageSelector).flexslider({
                animation: 'slide',
                controlNav: true,
                animationLoop: false,
                slideshow: false,
                direction: direction,
                sync: thumbnailSelector,
                before: (slider) => {
                    this.SetThumbnailImage(slider);
                },
                start: (slider) => {
                    this.SetThumbnailImage(slider);
                    $('.image-loading').removeClass('image-loading');
                    $('.images-loading').removeClass('images-loading');
                    this.DefineSliderEvent(slider, 'prev');
                    this.DefineSliderEvent(slider, 'next');
                },
            });
        },
        SetThumbnailImage(slider) {
            setTimeout(() => {
                this.thumbnailSrc = slider
                    .find('.flex-viewport li.flex-active-slide img')
                    .attr('src');
            });
        },
        DefineSliderEvent(slider, button) {
            slider
                .find('.flex-' + button)
                .off('click touchend')
                .on('mousedown touchstart', function (e) {
                if ($(e.target).hasClass('flex-disabled')) {
                    return;
                }
                slider.flexslider(button);
            });
        },
        getVariationsList() {
            return this.variations ? JSON.parse(this.variations) : [];
        },
        InitVariations: function () {
            if (this.variationsList) {
                this.skuList = this.skus ? JSON.parse(this.skus) : [];
                let current = find(this.variationsList.variations, (item) => {
                    return item.id === this.variationsList.default;
                });
                this.current = current ? JSON.parse(JSON.stringify(current)) : null;
                this.SetVariationId(this.current.id);
                // this.VariationChanged();
                // setTimeout(() => {
                //   if (this.current) {
                //     window.eventHub.$emit('VariationChanged', this.current.id);
                //   }
                // });
            }
        },
        SetCartIcon(product, add) {
            return add;
        },
        // SetVariationOption(option: number, value: string): void {
        //   this.$set(this.current.values, option, value);
        //   let code = this.GenerateCode(this.current.values);
        //   let current = find(this.variationsList.variations, (item: any) => {
        //     return item.code === code;
        //   });
        //   if (!current) {
        //     current = find(this.variationsList.variations, (item: any) => {
        //       return item.values[option] == value;
        //     });
        //   }
        //   this.current = JSON.parse(JSON.stringify(current));
        //   this.SetVariationId(this.current.id);
        //   this.VariationChanged();
        //   window.eventHub.$emit('VariationChanged', this.current.id);
        // },
        // VariationChanged(): void {
        //   let sku = find(this.skuList, (item: any) => {
        //     return item.id == this.id;
        //   });
        //   this.DisableUnavailableOptions();
        //   if (sku) {
        //     this.availability = sku.availability;
        //     this.raw_price = sku.price;
        //     this.price = sku.price_formatted;
        //     this.original_price = sku.original_price_formatted;
        //     this.original_calculated_price_formatted =
        //       this.raw_price < sku.original_calculated_price &&
        //       sku.original_calculated_price_formatted
        //         ? sku.original_calculated_price_formatted
        //         : null;
        //     this.percentage_discount = sku.percentage_discount;
        //     this.discount_formatted = sku.discount_formatted;
        //     this.multiPurchase = sku.multiple_purchase;
        //     this.qty = 1;
        //     this.installment12 = sku.installment12;
        //     this.installment24 = sku.installment24;
        //   }
        // },
        // DisableUnavailableOptions(): void {
        //   let currentAttributes = find(
        //     this.variationsList.variations,
        //     (item: any) => {
        //       return item.id == this.id;
        //     }
        //   );
        //   if (currentAttributes) {
        //     forEach(this.variationsList.attributes, (attr: any, key: number) => {
        //       forEach(attr.values, (item: any) => {
        //         let newOption = <any>{};
        //         Object.assign(newOption, currentAttributes.values);
        //         newOption[key] = item.id;
        //         let code = this.GenerateCode(values(newOption));
        //         let available = find(
        //           this.variationsList.variations,
        //           (item: any) => {
        //             return item.code === code;
        //           }
        //         );
        //         item.disabled = !available;
        //       });
        //     });
        //   }
        // },
        SetVariationId(id) {
            this.id = id;
        },
        // GenerateCode(values: any): string {
        //   return values.join('|');
        // },
        // ChangeVariationId(id: number): void {
        //   if (id !== this.id) {
        //     this.SetVariationId(id);
        //     let current = find(this.variationsList.variations, (item: any) => {
        //       return item.id === id;
        //     });
        //     this.current = JSON.parse(JSON.stringify(current));
        //     this.VariationChanged();
        //   }
        // },
    },
});
