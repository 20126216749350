import Vue from 'vue';
import ApiRequest from '@services/ApiRequestService';
import SimpleValidation from '@mixins/SimpleValidation';
const ComponentProps = Vue.extend({
    props: {
        id: Number,
        ariaLabel: String,
        favorites: {},
    },
    data: function () {
        return {
            favoritesUrl: '/favorites/',
            favoriteAvailability: [],
        };
    },
    mixins: [SimpleValidation],
    mounted() {
        let exists = false;
        for (var i in this.favorites) {
            if (this.favorites[this.id] !== undefined) {
                exists = true;
            }
        }
        if (exists) {
            this.SetFavoritesIcon(this.id, true);
        }
        else {
            this.SetFavoritesIcon(this.id, false);
        }
        window.eventHub.$on('FavoriteIconChanged', (data) => {
            this.updateIcon(data);
        });
    },
    methods: {
        updateIcon: function (data) {
            this.$set(this.favoriteAvailability, data.favorite, data.show);
        },
        SetFavoritesIcon(favorite, show) {
            this.$set(this.favoriteAvailability, favorite, show);
            window.eventHub.$emit('FavoriteIconChanged', {
                favorite: favorite,
                show: show,
            });
        },
        AddToFavorites(id) {
            ApiRequest.post(this.favoritesUrl + id).then((response) => {
                window.notifications.$flash(response.data.message, 'success');
                this.SetFavoritesIcon(id, true);
                window.eventHub.$emit('AddToFavorites', id);
            }, (error) => {
                if (error.message) {
                    window.notifications.$flash(error.message, 'error');
                }
            });
            return;
        },
        RemoveFromFavorites(id) {
            ApiRequest.delete(this.favoritesUrl + id).then((response) => {
                window.notifications.$flash(response.data.message, 'success');
                this.SetFavoritesIcon(id, false);
                window.eventHub.$emit('RemoveFromFavorites', id);
            }, (error) => {
                if (error.message) {
                    window.notifications.$flash(error.message, 'error');
                }
            });
            return;
        },
    },
});
export default class ProductFavoritesComponent extends ComponentProps {
}
