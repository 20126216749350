var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "favorites-wrapper favorites-icon" }, [
    _vm.favoriteAvailability[_vm.id] === true
      ? _c("div", {}, [
          _c(
            "button",
            {
              staticClass: "btn btn-icon btn-md p-0",
              attrs: { type: "submit", "aria-label": _vm.ariaLabel },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.RemoveFromFavorites(_vm.id)
                }
              }
            },
            [_c("i", { staticClass: "fas fa-btn fa-heart" })]
          )
        ])
      : _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-icon btn-md p-0",
              attrs: { type: "submit", "aria-label": _vm.ariaLabel },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.AddToFavorites(_vm.id)
                }
              }
            },
            [_c("i", { staticClass: "fal fa-btn fa-heart" })]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }