import Vue from 'vue';
import DeviceCheck from '@mixins/DeviceCheck';
export default Vue.extend({
    mixins: [DeviceCheck],
    mounted() {
        this.bindDocumentListener();
        this.bindHideListener();
    },
    methods: {
        /**
         * Clicking outside of the tooltip
         */
        bindDocumentListener: function () {
            document.addEventListener('click', (e) => {
                if (e.target.classList.contains('js_tooltip-info-click-tooltip')) {
                    return;
                }
                if (!e.target.classList.contains('js_tooltip-info-click-exceptions')) {
                    this.$root.$emit('bv::hide::tooltip');
                    let clickMarkers = document.getElementsByClassName('js_tooltip-info-click-trigger');
                    for (let i in clickMarkers) {
                        try {
                            clickMarkers[i].classList.remove('active');
                        }
                        catch (e) {
                            continue;
                        }
                    }
                }
            });
        },
        /**
         * Hiding event prevent default for desktop
         */
        bindHideListener: function () {
            this.$root.$on('bv::tooltip::hide', (bvEvent) => {
                if (this.isTouchDevice()) {
                    bvEvent.target.classList.remove('active');
                    return;
                }
                const isActive = undefined === bvEvent
                    ? false
                    : bvEvent.target.classList.contains('active');
                if (isActive) {
                    bvEvent.preventDefault();
                    return;
                }
            });
        },
        /**
         * Checking if touch device
         */
        isTouchDevice() {
            return this.touchDevice;
        },
        /**
         * Click event general method
         *
         * @param e
         * @constructor
         */
        TooltipClickEvent: function (e) {
            this.StateChange(e.currentTarget);
        },
        /**
         * General state change and class switching
         *
         * @param target
         * @constructor
         */
        StateChange: function (target) {
            const id = target.getAttribute('id');
            const isActive = target.classList.contains('active');
            if (!isActive) {
                target.classList.add('active');
                if (this.isTouchDevice()) {
                    this.$refs[id].$emit('open');
                }
                return;
            }
            this.$refs[id].$emit('close');
            target.classList.remove('active');
            if (this.isTouchDevice()) {
                this.$refs[id].$emit('bv::hide::tooltip');
                this.$nextTick(() => {
                    this.$refs[id].$el.blur();
                });
            }
        },
    },
});
