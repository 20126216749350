import Vue from 'vue';
import SimpleValidation from '@mixins/SimpleValidation';
import LazyLoad from 'vanilla-lazyload';
import Info from '@mixins/Info';
import ProductFavoritesComponent from '@components/ProductFavoritesComponent.vue';
import ProductOptions from '@mixins/ProductOptions';
import DeviceCheck from '@mixins/DeviceCheck';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
Vue.use(breakpoints);
export default Vue.extend({
    data() {
        return {
            availability: String,
            price: Number,
            original_price: Number,
            original_calculated_price: Number,
            original_calculated_price_formatted: Number,
            percentage_discount: Number,
            discount_formatted: String,
            multiPurchase: Boolean,
            skuList: Array,
            current: {},
            currentFormatted: {},
            // installment12: String,
            // installment24: String,
        };
    },
    mixins: [SimpleValidation, Info, ProductOptions, DeviceCheck],
    components: { ProductFavoritesComponent },
    mounted() {
        var myLazyLoad = new LazyLoad({
            elements_selector: '.lazy',
        });
    },
    methods: {
        // GenerateCode(values: any): string {
        //   return values.join('|');
        // },
        // VariationChanged(): void {
        //   let variation = find(this.skuList, (item: any) => {
        //     return item.id == this.id;
        //   });
        //   this.DisableUnavailableOptions();
        //   if (variation) {
        //     this.availability = variation.availability_name;
        //     this.raw_price = variation.price;
        //     this.price = variation.price_formatted;
        //     this.original_price = variation.original_price_formatted;
        //     this.original_calculated_price = variation.original_calculated_price;
        //     this.original_calculated_price_formatted =
        //       this.raw_price < this.original_calculated_price &&
        //       variation.original_calculated_price_formatted
        //         ? variation.original_calculated_price_formatted
        //         : null;
        //     this.percentage_discount = variation.percentage_discount;
        //     this.discount_formatted = variation.discount_formatted;
        //     this.multiPurchase = variation.multiple_purchase;
        //     this.qty = 1;
        //     this.installment12 = variation.installment12;
        //     this.installment24 = variation.installment24;
        //   }
        // },
        // CancelShowUp: function (e: any) {
        //   if (this.previewIsAvailable) {
        //     clearTimeout(this.previewTimer);
        //     window.eventHub.$emit('previewInactive');
        //   }
        // },
        SwaOpenProduct: function (value) {
            window.QsshopSwa.triggerResetIframeSize();
            window.QsshopSwa.sendActiveProduct(value);
        },
    },
});
